var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.item.id
    ? _c(
        "div",
        { staticClass: "full-land" },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "image",
                  staticStyle: { "z-index": "1" },
                  attrs: { cols: "10", md: "6", offset: "1", "offset-md": "0" },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex flex-column align-center justify-space-between",
                    },
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "name",
                          class: "element-" + _vm.item.wizarre.element || "",
                        },
                        [_vm._v(" " + _vm._s(_vm.item.name) + " ")]
                      ),
                      _vm.displayImage
                        ? _c("land-full-image", { attrs: { nft: _vm.item } })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _c(
                "v-col",
                {
                  staticStyle: { "z-index": "5" },
                  attrs: { cols: "10", md: "6", offset: "1", "offset-md": "0" },
                },
                [
                  _vm.isOfferActive
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "box-decorated-edges-mini common-bg section-buttons",
                        },
                        [
                          _c("asset-take-offer-popup", {
                            attrs: { activeOffer: _vm.activeOffer },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isOwner && !_vm.isOfferActive
                    ? _c(
                        "v-row",
                        {
                          staticClass:
                            "box-decorated-edges-mini common-bg section-buttons",
                          attrs: { dense: "" },
                        },
                        [
                          !_vm.isOfferActive
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("land-transfer-popup", {
                                    attrs: { nft: _vm.item },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isOfferActive
                            ? _c(
                                "v-col",
                                { attrs: { cols: "6" } },
                                [
                                  _c("asset-create-offer-popup", {
                                    attrs: {
                                      assetDTO: _vm.landDTO,
                                      assetType: _vm.assetType,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "section-details common-color common-bg box-decorated-edges-mini pa-8",
                    },
                    [
                      _c("p"),
                      "NOT_AVAILABLE" === _vm.item.status
                        ? _c("div", [
                            _vm._v(" Owner: "),
                            _c("span", [_vm._v("Reserved")]),
                          ])
                        : _c(
                            "div",
                            [
                              _vm._v(" Owner: "),
                              _c(
                                "span",
                                {
                                  directives: [
                                    { name: "title", rawName: "v-title" },
                                  ],
                                  attrs: { title: _vm.assetOwner },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("shortAddress")(_vm.assetOwner)
                                    )
                                  ),
                                ]
                              ),
                              _c("copy-button", {
                                attrs: { text: _vm.assetOwner },
                              }),
                            ],
                            1
                          ),
                      _c("p"),
                      _c("br"),
                      _vm.isCooldownActive
                        ? _c(
                            "p",
                            [
                              _vm._v(" Recharging: "),
                              _c("timer", {
                                attrs: {
                                  deadlineTimestamp:
                                    _vm.cooldownDateMicroTimestampUTC / 1000,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("p", [
                        _vm._v(" Type: "),
                        _c("span", [_vm._v(_vm._s(_vm.item.wizarre.typeName))]),
                      ]),
                      _c(
                        "p",
                        [
                          _vm._v(" Coordinates: "),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                to: {
                                  name: "Map",
                                  query: {
                                    x: _vm.item.wizarre.pos_x,
                                    y: _vm.item.wizarre.pos_y,
                                  },
                                },
                                small: "",
                              },
                            },
                            [
                              _c("span", [
                                _vm._v(
                                  _vm._s(_vm.item.wizarre.pos_x) +
                                    "✕" +
                                    _vm._s(_vm.item.wizarre.pos_y)
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c("p", [
                        _vm._v(" Element: "),
                        _c(
                          "span",
                          {
                            staticClass: "text-capitalize",
                            class:
                              "text-element-" + _vm.item.wizarre.element || "",
                          },
                          [
                            _c("v-icon", { attrs: { small: "" } }, [
                              _vm._v(
                                "mdi-" +
                                  _vm._s(
                                    _vm.elementIcon(_vm.item.wizarre.element)
                                  )
                              ),
                            ]),
                            _vm._v(
                              " " + _vm._s(_vm.item.wizarre.element) + " "
                            ),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "p",
                        [
                          _vm._v(" Rarity: "),
                          _c("rarity-text", {
                            attrs: { rarity: _vm.item.wizarre.rarity },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "section-spacer" }),
                      _c("p", { staticClass: "font-weight-bold" }, [
                        _vm._v("Power"),
                      ]),
                      _vm.item.wizarre.type
                        ? _c("span", [
                            _vm._v(" " + _vm._s(_vm.item.description) + " "),
                          ])
                        : _vm._e(),
                      _c("div", { staticClass: "section-spacer" }),
                      _c(
                        "div",
                        [
                          _c("p", { staticClass: "font-weight-bold" }, [
                            _vm._v("Used to summon"),
                          ]),
                          _vm.loadingSummonedBy
                            ? _c("v-progress-circular", {
                                staticStyle: { left: "40%" },
                                attrs: {
                                  indeterminate: "",
                                  size: 100,
                                  color: "amber",
                                },
                              })
                            : _vm._e(),
                          !_vm.loadingSummonedBy
                            ? _c("div", [
                                _vm.summonedBy.length > 0
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap",
                                      },
                                      _vm._l(_vm.summonedBy, function (item) {
                                        return _c(
                                          "div",
                                          { key: item.image },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "Wizard",
                                                    params: { id: item.id },
                                                  },
                                                },
                                              },
                                              [
                                                _c("mini-wizard", {
                                                  attrs: { item: item },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      0
                                    )
                                  : _c("div", [
                                      _c("span", [
                                        _vm._v(
                                          "This Land wasn't used in Ritual of Summoning yet"
                                        ),
                                      ]),
                                    ]),
                              ])
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }