<template>
    <div class="full-land" v-if="item.id">
        <v-row>
            <v-col class="image" cols="10" md="6" offset="1" offset-md="0" style="z-index: 1">
                <div class="d-flex flex-column align-center justify-space-between">
                    <v-chip :class="'element-'+item.wizarre.element||''" class="name">
                        {{ item.name }}
                    </v-chip>
                    <land-full-image v-if="displayImage" :nft="item"></land-full-image>
                </div>
            </v-col>
            <v-col cols="10" md="6" offset="1" offset-md="0" style="z-index: 5">
                <div
                    v-if="isOfferActive"
                    class="box-decorated-edges-mini common-bg section-buttons"
                >
                    <asset-take-offer-popup
                        :activeOffer="activeOffer"
                    />
                </div>
                <v-row v-if="isOwner && !isOfferActive"
                       class="box-decorated-edges-mini common-bg section-buttons"
                       dense
                >
                    <v-col v-if="!isOfferActive" cols="6">
                        <land-transfer-popup :nft="item"/>
                    </v-col>
                    <v-col v-if="!isOfferActive" cols="6">
                        <asset-create-offer-popup
                            :assetDTO="landDTO"
                            :assetType="assetType"
                        />
                    </v-col>
                </v-row>
                <div class="section-details common-color common-bg box-decorated-edges-mini pa-8">
                    <p>
                        <div v-if="'NOT_AVAILABLE' === item.status">
                            Owner: <span>Reserved</span>
                        </div>
                        <div v-else>
                            Owner: <span v-title :title="assetOwner">{{ assetOwner | shortAddress }}</span>
                            <copy-button :text="assetOwner"></copy-button>
                        </div>
                    </p>
                    <br/>

                    <p v-if="isCooldownActive">
                        Recharging:
                        <timer :deadlineTimestamp="(cooldownDateMicroTimestampUTC/1000)"></timer>
                    </p>
                    <p>
                        Type:
                        <span>{{ item.wizarre.typeName }}</span>
                    </p>
                    <p>
                        Coordinates:

                        <v-btn :to="{ name: 'Map', query: {x:item.wizarre.pos_x, y:item.wizarre.pos_y}}" small>
                            <span>{{ item.wizarre.pos_x }}✕{{ item.wizarre.pos_y }}</span>
                        </v-btn>
                    </p>
                    <p>
                        Element:
                        <span :class="'text-element-'+item.wizarre.element||''" class="text-capitalize">
                            <v-icon small>mdi-{{ elementIcon(item.wizarre.element) }}</v-icon>
                            {{ item.wizarre.element }}
                        </span>
                    </p>
                    <p>
                        Rarity:
                        <rarity-text :rarity="item.wizarre.rarity"></rarity-text>
                    </p>

                    <div class="section-spacer"></div>

                    <p class="font-weight-bold">Power</p>
                    <span v-if="item.wizarre.type">
                        {{ item.description }}
                    </span>

                    <div class="section-spacer"></div>

                    <div>
                        <p class="font-weight-bold">Used to summon</p>
                        <v-progress-circular
                            v-if="loadingSummonedBy"
                            indeterminate
                            :size="100"
                            color="amber"
                            class=""
                            style="left:40%"
                        ></v-progress-circular>
                        <div v-if="!loadingSummonedBy">
                            <div v-if="summonedBy.length > 0"
                                 class="d-inline-flex justify-center justify-sm-start align-start flex-row flex-wrap">
                                <div v-for="item in summonedBy" :key="item.image">
                                    <router-link :to="{ name: 'Wizard', params: {id: item.id}}">
                                        <mini-wizard
                                            :item="item"
                                        ></mini-wizard>
                                    </router-link>
                                </div>
                            </div>
                            <div v-else>
                                <span>This Land wasn't used in Ritual of Summoning yet</span>
                            </div>
                        </div>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import RarityText from "./rarity-text";
import LandFullImage from "./land-full-image";
import Timer from "../../timer";
import {landCooldownMixin} from "../../../mixins/landCooldownMixin";
import LandTransferPopup from "./land-transfer-popup";
import AssetTakeOfferPopup from "@/components/market/asset-take-offer-popup";
import AssetCreateOfferPopup from "@/components/market/asset-create-offer-popup";
import {AssetType} from "@/classes/asset/AssetType";
import AssetDTO from "@/classes/asset/AssetDTO";
import OfferDTO from "@/classes/market/OfferDTO";
import {OfferStatus} from "@/classes/market/OfferStatus";
import {apiServiceVue} from "../../../services/apiService.vue";
import MiniWizard from "../wizards/mini-wizard";
import CopyButton from "../../ui/copy-button";

const elementIcon = {
    fire: 'fire',
    water: 'water',
    nature: 'leaf',
}

export default {
    components: {
        CopyButton,
        MiniWizard,
        AssetCreateOfferPopup,
        AssetTakeOfferPopup, LandTransferPopup, Timer, LandFullImage, RarityText
    },
    mixins: [landCooldownMixin],
    props: {
        item: Object,
        isOwner: {
            type: Boolean,
            default: false
        },
        showStatistics: {
            type: Boolean,
            default: true
        },
        activeOffer: {
            type: OfferDTO,
            default: undefined,
        },
    },
    data() {
        return {
            assetType: AssetType.LAND,
            displayImage: true,
            summonedBy: [],
            loadingSummonedBy: true,
        }
    },
    mounted() {
        this.fetchSummonedByWizards(this.item.id);
    },
    computed: {
        isOfferActive() {
            return Boolean(this?.activeOffer?.status === OfferStatus.AVAILABLE)
        },
        landDTO() {
            return new AssetDTO(this.item)
        },
        assetOwner() {
            return String(this.activeOffer?.creator || this.item.owner);
        },
    },
    methods: {
        async fetchSummonedByWizards(landId) {
            this.loadingSummonedBy = true;
            this.summonedBy = [];
            const url = process.env.VUE_APP_WIZARD_NFT_SERVER_URL + 'summoned/land/' + landId
            await apiServiceVue.get(url).then(res => {
                this.summonedBy = res.data;
                this.loadingSummonedBy = false;
            });
        },
        elementIcon(elementName) {
            return elementIcon[elementName];
        },
    },
    watch: {
        item() {
            this.displayImage = false;
            setTimeout(() => this.displayImage = true, 50);
        },
    }
};
</script>

<style lang="scss" scoped>
.common-bg {
    background-color: rgba(42, 28, 51);
}

.v-card__text {
    display: flex;
    flex-direction: column;
}

.element-bg {
    border-radius: 5px 5px 0 0;
    padding: 0 0 0 5px;
}

.image {
    position: relative;
    height: 800px;
}

.full-land {
    .name {
        width: 100%;
        height: 50px;
        color: var(--v-background-base);
        font-weight: bold;
        text-align: center;
        font-size: 220%;
        justify-content: center;
        border-radius: 6px;
        margin-left: -10px;
    }


    .text-element-neutral,
    .name.element-neutral {
        background-color: var(--v-secondary-base);
    }

    .text-element-water i,
    .text-element-water {
        color: #01a8ec;
    }

    .name.element-water {
        background-color: #01a8ec;
    }

    .text-element-fire i,
    .text-element-fire {
        color: #ec3c00;
    }

    .name.element-fire {
        background-color: #ec3c00;
    }

    .text-element-nature i,
    .text-element-nature {
        color: #77b000;
    }

    .name.element-nature {
        background-color: #77b000;
    }


    .v-card span {
        text-transform: capitalize;
    }
}


@media only screen and (max-width: $sm) {
    .image {
        height: 550px;
    }
}

@media only screen and (max-width: $xs) {
    .image {
        height: 400px;
    }
}
</style>

<style lang="scss" scoped>
.common-color {
    color: #b69366;
}

.common-bg {
    background-color: rgba(42, 28, 51);
}

.highlighted-part {
    height: 200px;
}

.single-spell {
    cursor: pointer;

    .spell-text, .bordered-icon {
        transition-duration: 250ms;
        transition-property: color, box-shadow;
    }

    &:hover .bordered-icon {
        box-shadow: 0 0 0 2px var(--v-golden-base) !important;
    }

    &:hover .spell-text {
        color: var(--v-golden-base);
    }

    .spell-text {
        margin: 0 2px;
    }
}

.bordered-icon {
    border: 4px solid black;
    border-radius: 100%;
    box-shadow: 0 0 0 2px #b49265;
}

.section-img-frame {
    max-width: calc(85vh - 125px);
    height: auto;
    margin: auto;
    text-align: center;
    position: sticky;
    top: 75px;

    .wizard-portrait {
        position: relative;

        .wizard-bg {
            width: 100%;
            height: auto;
            position: relative;
            display: block;
        }

        .wizard-title {
            width: 86%;
            height: 13%;
            top: 0.5%;
            left: 7%;
            right: auto;
            margin: auto;
            position: absolute;
            color: #000;
            font-size: 160%;
            line-height: 120%;
            font-weight: bold;
            display: flex;
            justify-content: center;
            align-items: center;
            align-content: center;
        }

        .wizard-image {
            top: 15%;
            width: 90%;
            left: 2.5%;
            right: auto;
            margin: auto;
            position: absolute;
            display: block;
        }
    }

    .wizard-spells {
        .icon-container {
            width: 100%;
            margin-bottom: -2.5%;
            cursor: pointer;

            img {
                width: 100%;
                height: auto;
                object-fit: contain;
            }
        }

        .ultimate {
            color: rgba(137, 108, 171);

            img {
                box-shadow: 0 0 0 2px rgba(137, 108, 171);
            }
        }
    }

    .wizard-spells-vertical-connector {
        border-left: 4px solid rgba(181, 147, 101);
        border-right: 4px solid rgba(181, 147, 101);
    }

    .wizard-spells-horizontal-connector {
        height: 65%;
        margin-left: -25px;
        border-top: 4px solid rgba(181, 147, 101);
        border-bottom: 4px solid rgba(181, 147, 101);
    }

}

.spell-popup {
    img {
        width: 80%;
        height: auto;
        object-fit: contain;
    }

    span {
        color: white;
    }

    .popup-header {
        font-size: 175%;
        border-bottom: 2px solid #b49265;
    }

    .spell-description {
        font-size: 120%;

        .fire {
            color: #fd4a24;
        }

        .nature {
            color: #7fc73f;
        }

        .water {
            color: #65bafe;
        }

        .buff {
            color: #a48a69;
        }

        .defensive {
            color: #82621f;
        }

        .offensive {
            color: #c75309;
        }

        .ultimate {
            color: #8867b3;
        }
    }
}

.section-details {
    .section-spacer {
        height: 0;
        border-top: 4px solid #382849;
        margin: 32px -32px
    }

    p {
        margin-bottom: 4px;
    }

    span {
        color: rgba(255, 255, 255);
    }

    .level-progress {
        max-width: 200px;
        border: 2.5px solid #bb9867;
    }

    .element-icon {
        margin-top: -10px;
    }
}

@media only screen and (max-width: $lg) {
    .section-img-frame {
        width: 100%;
    }
    .wizard-spells {
        font-size: 85%;
        line-height: 110%;

        .icon-container {
            width: 75% !important;
        }
    }
}

@media only screen and (max-width: $md) {
    .wizard-spells {
        font-size: 65%;
    }
}

@media only screen and (max-width: $sm) {
    .wizard-spells {
        font-size: 95%;
        line-height: 115%;

        .icon-container {
            width: 90% !important;
        }
    }

    .spell-popup {
        img {
            width: 60%;
        }

        .popup-header {
            font-size: 135% !important;
        }
    }
}


@media only screen and (max-width: $xs) {
    .section-img-frame {
        .wizard-title {
            font-size: 100%;
        }
    }

    .single-spell {
        width: 34%;
        margin: 4px;

        .wizard-spells {
            font-size: 75%;
            line-height: 120%;

            .icon-container {
                width: 75% !important;
            }
        }
    }

    .spell-popup {
        img {
            width: 45px;
            margin-right: 15px;
        }

        .popup-header {
            font-size: 80% !important;
        }
    }
}

@media only screen and (max-height: 550px) {

    .section-img-frame {
        max-width: 330px;
    }
}
</style>
