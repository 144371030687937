var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "land-image d-flex flex-column",
      class: "portal-type-" + _vm.nft.wizarre.type,
    },
    [
      _vm.landImgLoading
        ? _c("v-progress-circular", {
            staticStyle: {
              position: "absolute",
              top: "200px",
              left: "40%",
              "z-index": "10",
            },
            attrs: { indeterminate: "", size: 200, color: "amber" },
          })
        : _vm._e(),
      !_vm.isEpicRarity && !_vm.landImgLoading
        ? _c("div", { staticClass: "standard-portal-container" }, [
            _c("img", {
              staticClass: "standard-portal-img",
              attrs: { src: _vm.imagesToPreload[2] },
            }),
          ])
        : _vm._e(),
      _vm.isEpicRarity && !_vm.landImgLoading
        ? _c("div", { staticClass: "anim-portal-img" }, [
            _c("img", {
              staticClass: "shine",
              attrs: { src: _vm.imagesToPreload[2] },
            }),
            _c("img", {
              staticClass: "feelingBg",
              attrs: { src: _vm.imagesToPreload[3] },
            }),
            _c("img", {
              staticClass: "feeling1",
              attrs: { src: _vm.imagesToPreload[4] },
            }),
            _c("img", {
              staticClass: "feeling2",
              attrs: { src: _vm.imagesToPreload[5] },
            }),
            _c("img", {
              staticClass: "frame",
              attrs: { src: _vm.imagesToPreload[6] },
            }),
          ])
        : _vm._e(),
      !_vm.landImgLoading
        ? _c("div", { staticClass: "hex-img" }, [
            _c("img", {
              staticClass: "hex",
              attrs: { src: _vm.imagesToPreload[0] },
            }),
            _c("img", {
              staticClass: "hex",
              attrs: { src: _vm.imagesToPreload[1] },
            }),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }