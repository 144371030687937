import { render, staticRenderFns } from "./land-full-image.vue?vue&type=template&id=578b761c&scoped=true"
import script from "./land-full-image.vue?vue&type=script&lang=js"
export * from "./land-full-image.vue?vue&type=script&lang=js"
import style0 from "./land-full-image.vue?vue&type=style&index=0&id=578b761c&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "578b761c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/wizarre-app-fe/wizarre-app-fe/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('578b761c')) {
      api.createRecord('578b761c', component.options)
    } else {
      api.reload('578b761c', component.options)
    }
    module.hot.accept("./land-full-image.vue?vue&type=template&id=578b761c&scoped=true", function () {
      api.rerender('578b761c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/assets/lands/land-full-image.vue"
export default component.exports