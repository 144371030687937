<template>
    <div class="d-block w-100">
        <main-button class="open-popup-btn" :on-click="openTransferPopup">
            Transfer
        </main-button>

        <dialog-box
            :model="transferPopupOpen"
            v-on:dialogClosed="transferPopupOpen=!transferPopupOpen"
            title="NFT transfer"
            width="500"
        >
            <div class="mt-5">
                <v-row no-gutters>
                    <v-col cols="12" sm="5">
                        <div class="d-flex">
                            <mini-land
                                :item="nft"
                            >
                            </mini-land>
                        </div>
                    </v-col>
                    <v-col cols="12" sm="7" class="text-center align-center">
                        <v-form v-model="isTransferFormValid">
                            <v-text-field
                                class="d-inline-block d-sm-block w-100"
                                v-model="transferRecipientAddress"
                                :rules="[v => v.length === 42 || 'Address has 42 characters']"
                                counter="42"
                                label="Recipient address"
                                color="#ccc"
                            ></v-text-field>

                            <br class="d-none d-sm-block"/><br/>

                            <main-button
                                class="mt-10 pull-right"
                                :disabled="!isWalletConnected || !isTransferFormValid"
                                :loading="transferInProgress"
                                :on-click="()=>transferClick(transferRecipientAddress, nft.id)"
                            >
                                Transfer
                            </main-button>
                        </v-form>
                    </v-col>
                </v-row>
            </div>
        </dialog-box>
    </div>
</template>

<script>

import MainButton from "../../ui/main-button";
import DialogBox from "../../dialog-box";
import MiniEnhancement from "../enhancements/mini-enhancement";
import InputAmount from "../../input-amount";
import MiniLand from "./mini-land";
import LandContract from "../../../services/contracts/landContract";

export default {
    components: {MiniLand, InputAmount, MiniEnhancement, DialogBox, MainButton},
    props: {
        nft: Object,
    },
    data() {
        return {
            transferInProgress: false,
            transferPopupOpen: false,
            transferRecipientAddress: '',
            isTransferFormValid: false,
        }
    },
    mounted() {

    },
    computed: {
        web3() {
            return this.$store.state.web3;
        },
        isWalletConnected() {
            return this.web3.isWalletConnected;
        },
    },
    methods: {
        openTransferPopup() {
            this.transferPopupOpen = true;
        },
        async transferClick(recipient, id) {
            this.transferInProgress = true;
            const enhancementContract = new LandContract(this.web3);
            enhancementContract.transfer(recipient, id)
                .then((res) => {
                    this.flashMessage.show({
                        status: 'success',
                        message: 'Item transfer completed'
                    });
                    const loadedEvent = new CustomEvent('reload-view');
                    window.dispatchEvent(loadedEvent);
                })
                .catch((error) => {
                    console.error(error);
                    this.flashMessage.show({
                        status: 'error',
                        message: 'Item transfer failed'
                    });
                })
                .finally(() => {
                    this.transferPopupOpen = false;
                    this.transferInProgress = false;
                });
        },
    },
};
</script>

<style lang="scss" scoped>
.open-popup-btn.main-button {
}
</style>
