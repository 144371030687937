<template>
    <div class="land-image d-flex flex-column" :class="'portal-type-'+nft.wizarre.type">
        <v-progress-circular
            indeterminate
            v-if="landImgLoading"
            :size="200"
            color="amber"
            class=""
            style="position: absolute; top:200px;left:40%;z-index:10"
        ></v-progress-circular>
        <div v-if="!isEpicRarity && !landImgLoading" class="standard-portal-container">
            <img class="standard-portal-img" v-bind:src="imagesToPreload[2]"/>
        </div>
        <div v-if="isEpicRarity && !landImgLoading" class="anim-portal-img">
            <img class="shine" :src="imagesToPreload[2]"/>
            <img class="feelingBg" :src="imagesToPreload[3]"/>
            <img class="feeling1" :src="imagesToPreload[4]"/>
            <img class="feeling2" :src="imagesToPreload[5]"/>
            <img class="frame" :src="imagesToPreload[6]"/>
        </div>
        <div v-if="!landImgLoading" class="hex-img">
            <img class="hex" :src="imagesToPreload[0]"/>
            <img class="hex" :src="imagesToPreload[1]"/>
        </div>
    </div>
</template>

<script>
const landElementColorName = {
    fire: 'r',
    water: 'b',
    nature: 'g',
};

export default {
    props: ['nft'],
    data() {
        return {
            landImgLoading: true,
            imagesToPreload: []
        }
    },
    created() {
        if (this.isEpicRarity) {
            this.imagesToPreload = [
                this.imageHexPath(this.nft.wizarre),
                this.imageHexPath(),
                this.imagePath(this.nft.wizarre, 2),
                this.imagePath(this.nft.wizarre, 3),
                this.imageWhirlPath(this.nft.wizarre),
                this.imageWhirlPath(this.nft.wizarre),
                this.imagePath(this.nft.wizarre, 1),
            ];
        } else {
            this.imagesToPreload = [
                this.imageHexPath(this.nft.wizarre),
                this.imageHexPath(),
                this.nft.image,
            ];
        }


        const images = this.imagesToPreload.map(imageSrc => {
            return new Promise((resolve, reject) => {
                const img = new Image();
                img.src = imageSrc;
                img.onload = resolve;
                img.onerror = reject;
            });
        });

        Promise.all(images).then(() => {
        }).catch(error => {
            console.error("Some image(s) failed loading!");
        }).finally(() => {
            this.landImgLoading = false;
        });

    },
    computed: {
        isEpicRarity() {
            return 'epic' === this.nft.wizarre.rarity;
        }
    },
    methods: {
        imagePath(nftParams, part) {
            return '/img/portal/600/epic/type-' + nftParams.type + '/' + landElementColorName[nftParams.element] + '_' + part + '.png';
        },
        imageWhirlPath(nftParams) {
            return '/img/portal/600/epic/whirl/whirl-' + landElementColorName[nftParams.element] + '.png';
        },
        imageHexPath(nftParams) {
            const prefix = '/img/portal/600/hex/hex';
            const suffix = '.png';
            if (nftParams) {
                return prefix + '-' + landElementColorName[nftParams.element] + suffix;
            }
            return prefix + suffix;
        },
    }
}
</script>

<style lang="scss" scoped>

.land-image {
    max-width: 100vw;
}

.standard-portal-container {
    position: relative;
    z-index: 5;
    height: 0;
    padding-left: 20px;

    .standard-portal-img {
        width: 100%;
        max-width: 550px;
        margin: 0 auto;
    }
}

.hex-img {
    width: 600px;
    top: 400px;
    left: -20px;
    position: relative;
    z-index: 0;

    img {
        position: absolute;
        width: 100%;
    }
}

.portal-type-3 {
    .frame,
    .shine,
    .feelingBg {
        margin-left: 6px;
    }
}

.anim-portal-img {
    width: 550px;
    position: relative;
    margin: 0 auto;
    z-index: 5;

    img {
        position: absolute;
        width: 100%;
    }

    .shine {
        animation: blink 3s linear infinite;
    }

    .feeling1,
    .feeling2 {
        width: auto;
        top: 90px;
        left: 150px;
        opacity: 0.5;
        animation: spinR 10s linear infinite;
    }

    .feeling2 {
        animation: spinL 10s linear infinite;
    }
}


@media only screen and (max-width: $lg) {
}

@media only screen and (max-width: $md) {
    .anim-portal-img {
        width: 400px;

        .feeling1,
        .feeling2 {
            top: 70px;
            left: 110px;
            width: 175px;
        }
    }
    .hex-img {
        width: 450px;
        top: 290px;
        left: -15px;
    }
}

@media only screen and (max-width: $sm) {
    .anim-portal-img {
        width: 350px;

        .feeling1,
        .feeling2 {
            top: 65px;
            left: 100px;
            width: 150px;
        }
    }

    .standard-portal-container {
        padding-left: 50px;

        .standard-portal-img {
            max-width: 350px;
        }
    }

    .hex-img {
        width: 450px;
        top: 250px;
        left: -10px;
    }
}

@media only screen and (max-width: $xs) {
    .hex-img {
        display: none;
    }
    .standard-portal-container {
        position: relative;
        padding-left: 0;
    }
    .standard-portal-img {
        min-width: 300px;
    }

    //.anim-portal-img {
    //    width: 200px;
    //
    //    .feeling1,
    //    .feeling2 {
    //        top: 40px;
    //        left: 60px;
    //        width: 80px;
    //    }
    //}
}

@keyframes blink {
    0%, 100% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    30%, 70% {
        opacity: 0.8;
    }
}

@keyframes spinR {
    100% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinL {
    0% {
        -webkit-transform: rotate(360deg);
        -moz-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

</style>
